import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import Img from 'gatsby-image';
import arrow from 'img/ui/arrow_white.svg';
import Banner from 'components/usability-testing/banner';

const HeatMapsForWebsite = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query HeatMapsForWebsite {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "heatmaps/heat-maps-for-website" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>Top benefits of using heat maps for a website </h2>

      <p>
        Among others, website heatmap tools can help you uncover on-site user behavior data and, as
        a result, analyze{' '}
        <Link
          href="https://www.searchenginejournal.com/use-heatmaps-seo-strategy/366268/#close"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          user intent
        </Link>
        . Here are three examples of heat map uses:
      </p>
      <h3>1. Boosting user engagement</h3>

      <p>
        Understanding which areas get the most attention will help you place critical elements and
        content where they get the most exposure. For instance, if you are running a promotion, and
        the hero image of your website is a “hot” area, this is where your visuals and clicks should
        be placed to get hits.
      </p>
      <Img
        fluid={images.engagement.node.childImageSharp.fluid}
        alt="heat maps for website as a way to boost engagement"
        title="Boosting user engagement"
      />
      <h3>2. Optimizing the information architecture</h3>

      <p>
        Another benefit of using a heat map on a website is information architecture optimization.
        Once you have determined the “hot” areas of your website through heat map usage, you can
        optimize the information layout on your website to suit user behavior.{' '}
      </p>
      <h3>3. Finding usability issues</h3>

      <p>
        If you notice a drop in user engagement on specific webpages, a heat map can help reveal any
        unusual activity worth investigating further. For example, you might see that there’s an
        unexpected{' '}
        <Link href="/heatmaps/click-maps/" target="_blank" rel="noopener noreferrer">
          number of clicks
        </Link>{' '}
        on an image, or that a recent change to the layout has made it confusing for visitors to
        engage with the content.
      </p>
      <Banner />
      <p>
        Now that we have covered some of the benefits of a heat map, let’s take a look at which
        pages you can use heat map tools on.
      </p>
      <h2>Which pages can you use your heat map tools on?</h2>
      <p>
        Using heat maps on a website will help you iron out any usability issues and inspire
        improvement ideas. Here are the top pages that you should consider using your heat map tools
        on.
      </p>
      <h3>Homepage</h3>
      <p>
        Your homepage is the first port of call for your web visitors. In many cases, it’s where
        people first come across your brand. This means that you cannot allow this page to have an
        unoptimized experience.
      </p>

      <p>So, how will a heatmap help you improve your homepage? </p>

      <p>
        Firstly, it can reveal where it’s best to place your Call to Actions (CTAs). For instance,
        if visitor data indicates that certain areas are “hot”, this can be the prime territory for
        your CTAs.
      </p>

      <Img
        fluid={images.features.node.childImageSharp.fluid}
        alt="Heat map on website – example of two CTAs above the fold."
        title="Features website - LiveSession"
      />

      <p>
        Secondly, it might reveal the site’s confusing elements. Say, for instance, that you run a
        marketing business and allow your customers to try it out for free for seven days. You will
        likely have a “Try For Free” button to draw users’ attention, right?
      </p>

      <p>
        However, if you place a “Contact Us” or “Sign Up for our Newsletter” right next to this
        button, it can prove confusing – after all, users might rather click on the contact button
        or simply scroll past! By consulting your website heat map for scroll rates and
        eye-tracking, you can, perhaps, remove some CTAs lower down your homepage and give more
        prominence to your main Call to Action.
      </p>
      <h3>Landing pages for specific products or campaigns</h3>

      <p>
        Imagine you’ve just launched a brand new, cutting-edge product on your eCommerce website.
        You simply cannot wait to start getting hits and sales… but the results are simply not
        coming in!
      </p>

      <p>
        When people visit campaign landing pages, they are certainly closer to converting and
        buying. However, if there are elements on your site such as broken links or confusing
        wording, they might not move down the sales funnel.
      </p>

      <p>
        Heat map usage can help you identify gaps in your website’s landing page layout. Do you have
        insufficiently enticing content above the fold? Or maybe your product images are not
        clickable, despite the amazing page view statistics? Cutting out the clutter will help
        ensure visitors keep scrolling down your landing page and have better engagement with its
        various elements.
      </p>

      <h3>Blog pages</h3>

      <p>
        Your blog is another important part of your website – likely, one that brings in the most
        organic traffic. It’s one of the places where you can add value for your customers, all the
        while establishing yourself as a thought leader. However, if your website heatmap tools
        indicate that users are simply not digesting the full content, you must use your data to
        determine the reason.
      </p>

      <p>
        Content length is often to blame – especially if you find that users are not reading through
        a full page. Although the word count doesn’t necessarily equate to the{' '}
        <Link
          href="https://www.searchenginejournal.com/use-heatmaps-seo-strategy/366268/#close"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          quality of your content
        </Link>
        , your website heat maps could indicate whether you need to assess the length of your
        content or play around with the design. Think – adding more images or placing a table of
        contents for easier navigation.
      </p>

      <Img
        fluid={images.ahrefs.node.childImageSharp.fluid}
        alt="A blog page is a good example of a heat maps for website use case."
        title="Blog pages"
      />

      <p>
        Source:{' '}
        <Link
          href="https://ahrefs.com/blog/best-marketing-newsletters/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Ahrefs
        </Link>
      </p>

      <p>
        Furthermore, if you see that there’s a particular place most blog readers look at, it might
        be a great place for your newsletter signup field.{' '}
      </p>
      <h3>Other pages to consider for heat map on website application</h3>

      <p>
        On top of your homepage, campaign landing pages, and blog, there are also other pages that
        you can consider in your website improvement efforts. Have a look at some additional heat
        map uses below:
      </p>
      <h4>Your website’s most popular landing pages</h4>
      <p>
        When you visit your Google Analytics account, you’ll be able to filter a list of the most
        visited landing pages. These are the webpages where you must guarantee the absolute best
        experience. Therefore, it’s worth studying user behavior to understand how visitors interact
        with their content. Were they drawn in by big, bold images or enticing CTA buttons? Did they
        scroll down the pages further than expected?{' '}
      </p>

      <p>
        These learnings could provide valuable insights for current and future pages. If users are
        interacting positively with content, you could use this data to improve your website’s other
        pages that are struggling with drop-offs and low conversion rates.
      </p>

      <h4>2. Newly launched pages </h4>
      <p>
        Using your website heatmap tools can assist in testing your website’s new design or layout,
        to check if people notice crucial information. Heat maps for your website will also be great
        for A/B testing of two versions of your new webpage. They can be used as part of your
        usability testing research to help you determine which layout will guarantee the best user
        experience.
      </p>
      <h4>3. Pages that have the highest conversion rate </h4>
      <p>
        It’s quite simple – if something has proven effective on your website in the past, it’s
        worth using it as a source of inspiration to replicate your success. For this reason, it’s
        worth placing a heat map on your highest converting webpages.
      </p>
      <p>
        That said, heat maps will also help ensure that these pages remain effective in the future.
        Having any glitches or issues on them could lower your conversion rate and cause visitors to
        not interact with your content. The consequences of leaving such issues unaddressed could be
        grave – it could hurt your business and drop the organic popularity of these pages all too
        quickly!
      </p>
      <h4>4. Pages with low conversion rates</h4>
      <p>
        Is your ‘About Us’ page not getting enough attention? Are users not scrolling further down
        the fold on your promotion pages?{' '}
        <Link
          href="https://www.searchenginejournal.com/use-heatmaps-seo-strategy/366268/#close"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Optimizing your page layout
        </Link>{' '}
        could improve your website considerably when guided by your website heat map tools.
      </p>

      <p>
        By taking into consideration aspects such as scrolling behavior and “cold” (less popular)
        areas on your website indicated by colors such as blues and greens, you can improve places
        that aren’t noticed by web visitors.{' '}
      </p>

      <h2>How to improve a website with a heat map – concluding thoughts </h2>

      <p>
        When you dedicate the time and effort to understand your{' '}
        <Link
          href="https://www.searchenginejournal.com/use-heatmaps-seo-strategy/366268/#close"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          website visitors’ behavior
        </Link>{' '}
        – i.e., why they scroll through pages or interact with certain content/elements more than
        with others, you’ll be on the right track to creating an optimal online experience.{' '}
      </p>

      <p>Using heat maps for a website enables you to: </p>
      <ul>
        <li>
          Verify if users are seeing your{' '}
          <Link
            href="https://wp.nyu.edu/dispatch/how-to-use-heatmaps-to-improve-customer-engagement/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            most important content
          </Link>
        </li>
        <li>
          Determine whether your website visitors are getting distracted by any elements throughout
          their journey
        </li>
        <li>See if people are clicking on the most important elements of each page, and</li>
        <li>Assist you in ironing out website components that cause friction and issues.</li>
      </ul>
      <p>
        If you want to improve your digital marketing efforts and conversion rates, heat maps will
        be a great start point. Investing the time and effort into your website’s UX will help your
        business stay at the top of its game. Thanks to heat map usage, you will be able to put
        yourself in the{' '}
        <Link
          href="https://neilpatel.com/blog/on-site-behavior/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          same frame of mind
        </Link>{' '}
        as your visitors and improve your website’s performance based on a custom-centric approach.
      </p>
      <div className="next-chapter">
        <Link href="/heatmaps/click-maps/">
          <div>
            <p>Chapter 4</p>
            <p>What are click maps and how to use them to boost engagement</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(HeatMapsForWebsite);
